:root {
  --bs-dark: #202020;
}

html {
  /* helps with auto scrolling to the top of the story */
  scroll-behavior: auto !important;
}

@font-face {
  font-family: "FS-Me";
  src: local("FS-Me"),
  url("shared/fonts/FS-Me.otf") format("opentype");
  font-weight: normal;
}

body {
  margin: 0;
  font-family: FS-Me, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--bs-white);
  background-color: #0F0F0F;
  user-select: none;
}

.text-justify {
  text-align: justify;
}

.full-height {
  min-height: 100vh;
}

.full-width {
  min-width: 100vw;
}

.perfectly-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cursor-pointer {
  cursor: pointer;
}

.container {
  padding: 0;
  max-width: 1200px;
}

a {
  color: var(--bs-white);
}